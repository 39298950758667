var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.tableData ? _c('b-card', {
    staticClass: "card-company-table",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-table', {
    staticClass: "mb-0",
    attrs: {
      "items": _vm.tableData,
      "responsive": "",
      "fields": _vm.fields
    },
    scopedSlots: _vm._u([{
      key: "cell(company)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-avatar', {
          attrs: {
            "rounded": "",
            "size": "32",
            "variant": "light-company"
          }
        }, [_c('b-img', {
          attrs: {
            "src": data.item.avatarImg,
            "alt": "avatar img"
          }
        })], 1), _c('div', [_c('div', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" " + _vm._s(data.item.title) + " ")]), _c('div', {
          staticClass: "font-small-2 text-muted"
        }, [_vm._v(" " + _vm._s(data.item.subtitle) + " ")])])], 1)];
      }
    }, {
      key: "cell(category)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-avatar', {
          staticClass: "mr-1",
          attrs: {
            "variant": data.item.avatarColor
          }
        }, [_c('feather-icon', {
          attrs: {
            "size": "18",
            "icon": data.item.avatarIcon
          }
        })], 1), _c('span', [_vm._v(_vm._s(data.item.avatarTitle))])], 1)];
      }
    }, {
      key: "cell(views)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "d-flex flex-column"
        }, [_c('span', {
          staticClass: "font-weight-bolder mb-25"
        }, [_vm._v(_vm._s(data.item.viewTitle))]), _c('span', {
          staticClass: "font-small-2 text-muted text-nowrap"
        }, [_vm._v(_vm._s(data.item.viewsub))])])];
      }
    }, {
      key: "cell(revenue)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s('$' + data.item.revenue) + " ")];
      }
    }, {
      key: "cell(sales)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('span', {
          staticClass: "font-weight-bolder mr-1"
        }, [_vm._v(_vm._s(data.item.sales + '%'))]), _c('feather-icon', {
          class: data.item.loss ? 'text-danger' : 'text-success',
          attrs: {
            "icon": data.item.loss ? 'TrendingDownIcon' : 'TrendingUpIcon'
          }
        })], 1)];
      }
    }], null, false, 2516755900)
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }