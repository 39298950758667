<template>
  <b-card title="Human Resource Chart">
    <app-echart-bar :option-data="option" />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue'

export default {
  components: {
    BCard,
    AppEchartBar,
  },
  data() {
    return {
      option: {
        xAxis: [
          {
            type: 'value',
          },
        ],
        yAxis: [
          {
            type: 'category',
            data: ['Now'],
            splitLine: { show: false },
          },
        ],
        grid: {
          left: '50px',
          right: '30px',
          bottom: '30px',
        },
        series: [
          {
            name: 'Now Online',
            type: 'bar',
            stack: 'advertising',
            data: [10],
            barWidth: '30%',
          },
          {
            name: 'User',
            type: 'bar',
            stack: 'advertising',
            data: [25],
            barWidth: '30%',
          },
          {
            name: 'Total Employee',
            type: 'bar',
            stack: 'advertising',
            data: [100],
            barWidth: '30%',
          },
          // {
          //   name: 'Point Four',
          //   type: 'bar',
          //   stack: 'advertising',
          //   data: [1],
          //   barWidth: '30%',
          //   itemStyle: {
          //     barBorderRadius: [0, 10, 10, 0],
          //   },
          // },
        ],
      },
    }
  },
}
</script>
