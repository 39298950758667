var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.data ? _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "align-items-baseline"
  }, [_c('div', [_c('b-card-title', [_vm._v("Sales")]), _c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Last 6 months ")])], 1), _c('b-dropdown', {
    staticClass: "chart-dropdown",
    attrs: {
      "variant": "link",
      "no-caret": "",
      "right": "",
      "toggle-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "text-body cursor-pointer",
          attrs: {
            "icon": "MoreVerticalIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }], null, false, 592634808)
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v(" Last 28 Days ")]), _c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v(" Last Month ")]), _c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v(" Last Year ")])], 1)], 1), _c('b-card-body', [_c('div', {
    staticClass: "d-inline-flex align-items-center mr-2"
  }, [_c('feather-icon', {
    staticClass: "text-primary mr-50",
    attrs: {
      "icon": "CircleIcon"
    }
  }), _c('span', [_vm._v("Sales")])], 1), _c('div', {
    staticClass: "d-inline-flex align-items-center"
  }, [_c('feather-icon', {
    staticClass: "text-info mr-50",
    attrs: {
      "icon": "CircleIcon"
    }
  }), _c('span', [_vm._v("Visits")])], 1), _c('vue-apex-charts', {
    attrs: {
      "type": "radar",
      "height": "275",
      "options": _vm.statisticsRadar.chartOptions,
      "series": _vm.data.series
    }
  })], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }