var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "dashboard-analytics"
    }
  }, [_c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "12"
    }
  }, [_c('analytics-congratulation', {
    attrs: {
      "data": _vm.data.congratulations
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_vm.data.subscribersGained ? _c('statistic-card-with-area-chart', {
    attrs: {
      "icon": "UsersIcon",
      "statistic": _vm.kFormatter(_vm.data.subscribersGained.analyticsData.subscribers),
      "statistic-title": "Subscribers Gained",
      "chart-data": _vm.data.subscribersGained.series
    }
  }) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_vm.data.ordersRecevied ? _c('statistic-card-with-area-chart', {
    attrs: {
      "icon": "PackageIcon",
      "color": "warning",
      "statistic": _vm.kFormatter(_vm.data.ordersRecevied.analyticsData.orders),
      "statistic-title": "Orders Received",
      "chart-data": _vm.data.ordersRecevied.series
    }
  }) : _vm._e()], 1)], 1), _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('analytics-avg-sessions', {
    attrs: {
      "data": _vm.data.avgSessions
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('analytics-support-tracker', {
    attrs: {
      "data": _vm.data.supportTracker
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('analytics-timeline', {
    attrs: {
      "data": _vm.data.timeline
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('analytics-sales-radar-chart', {
    attrs: {
      "data": _vm.data.salesChart
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('analytics-app-design', {
    attrs: {
      "data": _vm.data.appDesign
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('invoice-list')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }