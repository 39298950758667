<template>
  <b-row>
    <b-col xl="12" lg="12" md="12" sm="12">
      <b-card no-body class="card-statistics">
        <b-card-header>
          <b-card-title>{{ $t('Procurement') }}</b-card-title>
          <b-card-text class="font-small-2 mr-25 mb-0">
            <!-- <b-button v-b-tooltip.hover.top="'Klik disini untuk filter tanggal dan tipe komisi'" variant="link"
              id="popover-reactive-1" ref="button">
              Filter
            </b-button> -->
            <!-- <b-link v-b-tooltip.hover.top="'Klik disini untuk ke menu Ajuan Komisi'"
              @click.prevent="$router.push(`/finance/komisi`)">
              <feather-icon icon="ExternalLinkIcon" size="18" class="cursor-pointer" />
            </b-link> -->
            <!-- <b-popover target="popover-reactive-1" triggers="click" :show.sync="popoverShow" placement="bottomleft"
              ref="popover" @show="onShow" @shown="onShown" @hidden="onHidden">
              <template #title>
                Filter
              </template>

<div>
  <b-form-group label="Tanggal Mulai" label-size="sm" label-cols="3" class="mb-1">
    <b-form-datepicker :date-format-options="{ year: '2-digit', month: 'short', day: '2-digit', weekday: 'short' }"
      size="sm" v-model="tanggal_mulai"></b-form-datepicker>
  </b-form-group>
  <b-form-group label="Tanggal Akhir" label-size="sm" label-cols="3" class="mb-1">
    <b-form-datepicker :date-format-options="{ year: '2-digit', month: 'short', day: '2-digit', weekday: 'short' }"
      size="sm" v-model="tanggal_received_stock"></b-form-datepicker>
  </b-form-group>
  <b-form-group label="Tipe" label-size="sm" label-cols="3" class="mb-1">
    <b-form-select v-model="tipekomisi" :options="options" @input="getRekapKomisi()" size="sm"></b-form-select>
  </b-form-group>
  <b-button @click="onClose" size="sm" variant="danger">Cancel</b-button>
  <b-button @click="onOk" size="sm" variant="primary">Ok</b-button>
</div>
</b-popover> -->
          </b-card-text>
        </b-card-header>
        <b-card-body class="statistics-body">
          <b-row>
            <b-col md="4" sm="6" class="mb-md-0">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="secondary">
                    <feather-icon size="24" icon="CircleIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ data ? data.in_transit_stock : 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('In Transit Stock') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col md="4" sm="6" class="mb-md-0">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="warning">
                    <feather-icon size="24" icon="LoaderIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ data ? data.on_checking_stock : 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('On Checking Stock') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col md="4" sm="6" class="mb-md-0">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="success">
                    <feather-icon size="24" icon="CheckCircleIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ data ? data.received_stock : 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('Received Stock') }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <!-- <b-col md="3" sm="6" class="mb-md-0">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="danger">
                    <feather-icon size="24" icon="XCircleIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ data ? data.dibatalkan : 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Batal
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col> -->
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BLink,
  BPopover,
  BAlert,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardGroup,
  BForm,
  BTable,
  VBTooltip,
  BFormDatepicker,
  BFormTimepicker,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BFormCheckbox,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BOverlay,
  BCardText,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";

export default {
  components: {
    BLink,
    BPopover,
    BAlert,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardGroup,
    BForm,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    VBTooltip,
    BFormTimepicker,
    BModal,
    BOverlay,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BCardText,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BMedia,
    BMediaAside,
    BMediaBody,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  watch: {
    input1(val) {
      if (val) {
        this.input1state = true
      }
    },
    input2(val) {
      if (val) {
        this.input2state = true
      }
    },
    transactionType(val) {
      if (val) {
        if (val == 1) {
          this.id_akun = this.debitAkun;
        } else {
          this.id_akun = this.kreditAkun;
        }
      }
    },
    // selectedStatus(status) {
    //   if (status) {
    //     this.getData();
    //   }
    // },
  },
  data() {
    return {
      data: null,
      loading: false,
      statusOptions: [
        {
          value: null,
          text: "Semua",
          warna: "primary",
        },
        {
          value: 'in_transit_stock',
          text: "Pending",
          warna: "info",
        },
        {
          value: 'on_checking_stock',
          text: "Proses",
          warna: "warning",
        },
        {
          value: 'received_stock',
          text: "Selesai",
          warna: "success",
        },
        {
          value: 'dibatalkan',
          text: "Batal",
          warna: "danger",
        },
      ],
      selectedStatus: 'in_transit_stock',
      jenisOptions: [
        {
          value: null,
          text: "Semua",
        },
        {
          value: "affiliate",
          text: "Komisi Affiliate",
        },
        {
          value: "cabang",
          text: "Komisi Cabang",
        },
        {
          value: "bulan",
          text: "Komisi Bulanan",
        },
      ],
      is_bundled: null,
      BundleOptions: [
        {
          value: 1,
          text: "Ya",
        },
        {
          value: null,
          text: "Tidak",
        },
      ],
      selectedjenis: null,
      jenisOptions2: [
        // {
        //   value: null,
        //   text: "Semua",
        // },
        {
          value: "affiliate",
          text: "Komisi Affiliate",
        },
        {
          value: "cabang",
          text: "Komisi Cabang",
        },
        // {
        //   value: "bulan",
        //   text: "Komisi Bulanan",
        // },
      ],
      selectedjenis2: 'affiliate',
      showModal: false,
      showModalApprove: false,
      required,
      password,
      email,
      confirmed,
      form: {
        id_gudang: "",
        tanggal: new Date(),
        keterangan: "",
      },
      id_gudang: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      perPage: 20,
      pageOptions: [20, 50, 60],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: true,
      sortDirection: "desc",
      filter: null,
      filterOn: [],
      detailmodal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      iberi: [
        {
          1: "Setuju",
          0: "Belum",
          null: "belum",
        },
        {
          1: "light-success",
          0: "light-danger",
          null: "light-danger",
        },
      ],
      statuskomisi: [
        // {
        //   value: "in_transit_stock",
        //   text: "Pending",
        // },
        {
          value: "on_checking_stock",
          text: "Proses",
        },
        {
          value: "received_stock",
          text: "Selesai",
        },
        {
          value: "dibatalkan",
          text: "Batal",
        },
      ],
      statuswarna: [
        {
          "in_transit_stock": "Pending",
          "on_checking_stock": "Proses",
          "received_stock": "Selesai",
          "dibatalkan": "Batal",
          null: "Belum",
        },
        {
          "in_transit_stock": "light-secondary",
          "on_checking_stock": "warning",
          "received_stock": "light-success",
          "dibatalkan": "light-danger",
          null: "light-info",
        },
      ],
      typewarna: [
        {
          "affiliate": "Affiliate",
          "cabang": "Cabang",
          null: "Belum",
        },
        {
          "affiliate": "light-info",
          "cabang": "light-success",
          null: "light-secondary",
        },
      ],
      iterima: [
        {
          1: "Terima",
          0: "Belum",
          null: "belum",
        },
        {
          1: "light-success",
          0: "light-danger",
          null: "light-danger",
        },
      ],
      fields: [
        {
          key: "urutan",
          label: "#",
        },
        {
          key: "no",
          label: "No",
        },
        { key: "tanggal", label: "Tgl Ajuan", sortable: true },
        { key: "gudang.nama_gudang", label: "Gudang" },
        { key: "keterangan", label: "Keterangan" },
        { key: "total", label: "total" },
        // { key: "diberikan", label: "Finance" },
        // { key: "diterima", label: "Status" },
        { key: "status2", label: "Status", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      fieldsFinance: [
        {
          key: "cek",
          label: "#",
        },
        {
          key: "urutan",
          label: "#",
        },
        // {
        //   key: "no",
        //   label: "No",
        // },
        { key: "created_at", label: "Tgl Ajuan", sortable: true },
        // { key: "gudang.nama_gudang", label: "Gudang" },
        { key: "member.nama_lengkap", label: "Nama" },
        { key: "type", label: "type" },
        { key: "nominal", label: "Total (Rp)" },
        { key: "status", label: "Status" },
        { key: "rekening.nama", label: "rekening" },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      status: [
        {
          1: "Belum",
          2: "Approve SPV",
          3: "Approve Gudang",
          4: "Sudah Ambil",
        },
        {
          1: "light-danger",
          2: "light-info",
          3: "light-warning",
          4: "light-success",
        },
      ],
      cek: [],
      showKonfirmasi: false,
      formkonfirmasi: {
        id: null,
        bukti_tf: {
          file: null,
        },
      },
      id_akun: [],
      id_kas: [],
      transactionType: null,
      debitAkun: [],
      kreditAkun: [],
      itemsrekap: null,
      input1: '',
      input1state: null,
      input2: '',
      input2state: null,
      options: [{ text: '- Pilih -', value: '' }, { text: 'Affiliate', value: 'affiliate' }, { text: 'Cabang', value: 'cabang' }],
      input1Return: '',
      input2Return: '',
      popoverShow: false,
      tipekomisi: 'affiliate',
      tanggal_mulai: null,
      tanggal_received_stock: null,
    };
  },
  computed: {
    isKreditAkun() {
      return this.transactionType == 2;
    },
    isDebitAkun() {
      return this.transactionType == 1;
    },
    totalKomisi() {
      if (!this.cek || this.cek.length < 1) {
        return 0;
      }

      return this.cek.reduce((nominal, item) => (nominal += parseInt(item.nominal)), 0);
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    sortOptions2() {
      // Create an options list from our fields
      return this.fieldsFinance
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  async mounted() {
    this.getData()
    // this.setDataSort()
    // await this.getDataKomisi();

    // this.getData();
    // this.getAkun();
    if (this.isFinance) {
      this.sortBy = ''
      this.sortDesc = true
    }
  },
  methods: {
    getNominal(id_kas) {
      return id_kas.reduce((total, id_kas) => total += id_kas.saldo, 0)
    },
    onClose() {
      this.popoverShow = false
    },
    onOk() {
      this.getRekapKomisi()
      if (!this.input1) {
        this.input1state = false
      }
      if (!this.input2) {
        this.input2state = false
      }
      if (this.input1 && this.input2) {
        this.onClose()
        // Return our popover form results
        this.input1Return = this.input1
        this.input2Return = this.input2
      }
    },
    onShow() {
      // This is called just before the popover is shown
      // Reset our popover form variables
      this.input1 = ''
      this.input2 = ''
      this.input1state = null
      this.input2state = null
      this.input1Return = ''
      this.input2Return = ''
    },
    onShown() {
      // Called just after the popover has been shown
      // Transfer focus to the first input
      this.focusRef(this.$refs.input1)
    },
    onHidden() {
      // Called just after the popover has finished hiding
      // Bring focus back to the button
      this.focusRef(this.$refs.button)
    },
    focusRef(ref) {
      // Some references may be a component, functional component, or plain element
      // This handles that check before focusing, assuming a `focus()` method exists
      // We do this in a double `$nextTick()` to ensure components have
      // updated & popover positioned first
      this.$nextTick(() => {
        this.$nextTick(() => {
          ; (ref.$el || ref).focus()
        })
      })
    },
    submitkonfirmasi() {
      this.$refs.vkonfirmasi.validate().then((success) => {
        if (success) {
          const payloadkonfirmasi = [];
          this.cek.forEach((konfir) => {
            let konfirmasi = {
              id: konfir.id,
              // status: this.formkonfirmasi.statuskomisi,
              // akun_id: this.formkonfirmasi.id_akun,
              // kas_id: this.formkonfirmasi.id_kas,
            };
            payloadkonfirmasi.push(konfirmasi);
          });
          this.$store
            .dispatch("ajuan/bundleon_checking_stock", payloadkonfirmasi)
            .then(() => {
              this.showKonfirmasi = false;
              delete this.cek;
              this.displaySuccess({
                text: "Berhasil dion_checking_stock",
              });
              this.getDataKomisi();
              // this.formKonfirmasi = null
            })
            .catch((e) => {
              this.label = "Submit";
              this.displayError(e);
              return false;
            });
        };
      }
      )
    },
    approveajuan() {
      this.showKonfirmasi = true
    },
    approveajuanon_checking_stock() {
      this.$swal({
        title: "Anda yakin?",
        text: "Data ajuan komisi ini akan dion_checking_stock",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const payloadkonfirmasi = [];
          this.cek.forEach((konfir) => {
            let konfirmasi = {
              id: konfir.id,
              // status: this.formkonfirmasi.statuskomisi,
              // akun_id: this.formkonfirmasi.id_akun,
              // kas_id: this.formkonfirmasi.id_kas,
            };
            payloadkonfirmasi.push(konfirmasi);
          })
          this.$store
            .dispatch("ajuan/bundleon_checking_stock", payloadkonfirmasi)
            .then(() => {
              this.getDataKomisi();
              this.displaySuccess({
                message: "Ajuan berhasil dion_checking_stock",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        } else {
          this.$swal({
            title: 'Batal',
            text: 'Data anda tidak berubah :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      });
    },
    async unduhbop(item) {
      this.loading = true
      const response = await this.$store.dispatch("ajuan/printAjuan", item.id);
      this.loading = false
      window.open(URL.createObjectURL(response))
    },
    async getStatus() {
      const currentMenu = await this.currentMenu();
      const params = {
        menu_id: currentMenu.id,
        level_id: this.user.level.id,
      };
      const statusAjuan = await this.$store.dispatch("statusrole/getData", params);
      statusAjuan.map((status) => {
        this.statusOptions.push({
          value: status.data_status,
          text: status.nama_status,
        });
      });
    },
    // downloadAttachment(ajuan_id) {
    //   window.open(`/pengambilan-komisi/download-attachment/${ajuan_id}`, "_blank");
    // },
    setStatus(status) {
      let result = {
        color: "primary",
        text: "",
      };
      switch (status) {
        case null:
        case 1:
          result = {
            color: "danger",
            text: "PENDING",
          };
          break;
        case 2:
          result = {
            color: "info",
            text: "DISETUJUI SPV",
          };
          break;
        case 3:
          result = {
            color: "dark",
            text: "DISETUJUI GUDANG",
          };
          break;
        case 4:
          result = {
            color: "success",
            text: "SELESAI",
          };
          break;
      }

      return result;
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    edit(item) {
      this.form = item;
      if (this.form.sales && !this.isAdminGudang)
        this.form.id_gudang = this.form.sales.id;
      this.id = item.id;
      this.showModal = true;
    },
    add() {
      this.resetForm();
      this.showModal = true;
    },
    approve(item) {
      this.form = item;
      this.showModalApprove = true;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: "Data ajuan komisi ini akan dihapus",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("ajuan/hapus", item)
            .then(() => {
              this.getData();
              this.displaySuccess({
                message: "Ajuan berhasil dihapus",
              });
              this.resetForm();
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        } else {
          this.$swal({
            title: 'Batal',
            text: 'Data anda aman :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      });
    },
    batalkan(item) {
      this.$swal({
        title: "Anda yakin?",
        text: "Data ajuan komisi ini akan dibatalkan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("ajuan/batal", item)
            .then(() => {
              this.getDataKomisi();
              this.displaySuccess({
                message: "Ajuan berhasil dibatalkan",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        } else {
          this.$swal({
            title: 'Batal',
            text: 'Data anda aman :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form.id = null;
      this.form.tanggal = new Date();
      this.form.keterangan = null;
      if (!this.isAdminGudang) {
        this.form.id_gudang = null;
      }
    },
    submit() {
      this.$refs.formajuan.validate().then(async (success) => {
        if (success) {
          if (this.isAdminGudang) {
            this.form.id_gudang = this.user.karyawan.id;
          }
          if (this.id) {
            this.form.id = this.id;
          }
          this.form.id_gudang = this.myGudang.id;
          if (!this.form.status) {
            this.form.status = 1;
          }
          if (!this.form.keterangan) {
            this.form.keterangan = "-";
          }
          const payload = this.form;
          try {
            this.label = "Loading...";
            const ajuan = await this.$store.dispatch("ajuan/save", [payload]);
            this.label = "Submit";
            if (this.id) {
              this.getData();
              this.displaySuccess({
                message: "Ajuan berhasil disimpan",
              });
            } else {
              this.displaySuccess({
                message: "Ajuan berhasil disimpan",
              });
              this.$router.push(`/biaya-operasional/detail/${ajuan.id}`);
            }
          } catch (e) {
            console.error(e);
            this.displayError(e);
            return false;
          }
        }
      });
    },
    getInfo(item) {
      const result = {
        variant: 'primary',
        kode: 10,
        status: '-'
      }
      if (item.received_stock == 0) {
        result.variant = 'light-primary'
        result.kode = 1
        result.status = 'Belum Selesai'
      }
      if (item.received_stock == 1 && item.tolak == 0 && item.id_akun == null) {
        result.variant = 'light-danger'
        result.kode = 2
        result.status = 'Pending'
      }
      else if ((item.received_stock == 1 || item.received_stock == 0) && item.tolak == 1) {
        result.variant = 'dark'
        result.kode = 3
        result.status = 'Ditolak Finance'
      }
      else if (item.received_stock == 1 && item.tolak == 0 && item.id_akun > 0 && item.diterima == null) {
        result.variant = 'light-success'
        result.kode = 4
        result.status = 'Disetujui Finance'
      }
      else if (item.received_stock == 1 && item.tolak == 0 && item.id_akun > 0 && item.diterima > 0) {
        result.variant = 'success'
        result.kode = 5
        result.status = 'Diterima Gudang'
      }

      return result
    },
    // async getData() {
    //   // set payload if sales itself, filter per sales itself
    //   let params = this.isAdminGudang ? { id_gudang: this.myGudang.id, order: "desc" } : { order: "desc" };

    //   this.loading = true;
    //   const ajuans = await this.$store.dispatch("ajuan/getData", params);
    //   ajuans.map(ajuan => ajuan.info = this.getInfo(ajuan))
    //   const belumAjuan = ajuans.filter(ajuan => ajuan.info.kode == 1)
    //   const in_transit_stockAjuan = ajuans.filter(ajuan => ajuan.info.kode == 2)
    //   const tolakAjuan = ajuans.filter(ajuan => ajuan.info.kode == 3)
    //   const completeFinanceAjuan = ajuans.filter(ajuan => ajuan.info.kode == 4)
    //   const completeAjuan = ajuans.filter(ajuan => ajuan.info.kode == 5)
    //   if (this.isFinance) {
    //     this.items = [...in_transit_stockAjuan, ...completeFinanceAjuan, ...completeAjuan, ...tolakAjuan, ...belumAjuan]
    //   }
    //   if (this.isAdminGudang) {
    //     this.items = [...belumAjuan, ...in_transit_stockAjuan, ...completeFinanceAjuan, ...completeAjuan, ...tolakAjuan]
    //   }
    //   this.loading = false;
    //   // this.items = ajuans;
    //   this.totalRows = ajuans.length;
    // },
    // getid_gudang() {
    //   let payload = {jabatan_id: 5}
    //   if (this.isCabang){
    //     payload.member_id = this.user.member_id
    //   }
    //   this.$store
    //     .dispatch("karyawan/getData", payload)
    //     .then(() => {
    //       let karyawan = JSON.parse(JSON.stringify(this.$store.state.karyawan.datas));
    //       karyawan.map((item) => {
    //         item.value = item.id;
    //         item.text = item.nama_lengkap;
    //       });

    //       this.id_gudang = karyawan;
    //     })
    //     .catch((e) => {
    //       this.displayError(e);
    //       return false;
    //     });
    // },
    // getsalesName(data) {
    //   return data.sales ? data.sales.nama_lengkap : "-";
    // },
    // getDataKomisi() {
    //   this.loading = true;
    //   const perPage = parseInt(this.perPage);
    //   const currentPage = (parseInt(this.currentPage) - 1) * perPage;
    //   const payload = {
    //     search: this.filter != null ? this.filter : null,
    //     order: "desc",
    //     start: currentPage,
    //     length: this.perPage,
    //     // filter_by: "nama_lengkap",
    //     type: this.selectedjenis != null ? this.selectedjenis : null,
    //     is_bundled: this.is_bundled != null ? this.is_bundled : null,
    //     status: this.selectedStatus != null ? this.selectedStatus : null,
    //     // paket_id: this.filterPaket != null ? this.filterPaket.id : null,
    //     // jadwal_id: this.filterJadwal != null ? this.filterJadwal.id : null,
    //     // user_id: this.user.id,
    //   };
    //   this.$store.dispatch("ajuan/getData", payload).then(() => {
    //     let items = JSON.parse(JSON.stringify(this.$store.state.ajuan.datas));
    //     let items_total = this.$store.state.ajuan.totals;
    //     this.items = items;
    //     this.totalRows = items_total;
    //     this.getRekapKomisi()
    //     this.loading = false;
    //     // this.items = this.$store.state.member.datas;
    //     // this.totalRows = this.items.length;
    //   });
    // },
    // getRekapKomisi() {
    //   this.loading = true;
    //   const payload = {
    //     type: this.tipekomisi != null ? this.tipekomisi : null,
    //     tanggal_mulai: this.tanggal_mulai != null ? this.tanggal_mulai : null,
    //     tanggal_received_stock: this.tanggal_received_stock != null ? this.tanggal_received_stock : null,
    //   };
    //   this.$store.dispatch("ajuanrekap/getRekapStatus", payload).then((res) => {
    //     let items = JSON.parse(JSON.stringify(this.$store.state.ajuanrekap.datas2));
    //     // let items_total = this.$store.state.ajuanrekap.totals2;
    //     this.itemsrekap = items;
    //     // this.totalRowsre = items_total;
    //     this.loading = false;
    //     // this.items = this.$store.state.member.datas;
    //     // this.totalRows = this.items.length;
    //   });
    // },
    // onFiltered(filteredItems) {
    //   // Trigger pagination to update the number of buttons/pages due to filtering
    //   this.totalRows = filteredItems.length;
    //   this.currentPage = 1;
    // },
    // dateDisabled(ymd, date) {
    //   // Disable weekends (Sunday = `0`, Saturday = `6`) and
    //   // disable days that fall on the 13th of the month
    //   const weekday = date.getDay();
    //   const day = date.getDate();
    //   // Return `true` if the date should be disabled
    //   return weekday === 0 || weekday === 6 || day === 13;
    // },
    getData() {
      let payload = {
        gudang_id: this.myGudang.id ? this.myGudang.id : this.myGudang.data.id
      }
      this.$store
        .dispatch("dashboard/heypenerimaan", payload)
        .then((response) => {
          // let hem = JSON.stringify(this.$store.state.dashboard.dataheypenerimaan)
          // console.log('aaaa', hem)
          // hem.map((item) => {
          //   item.value = item.id;
          //   item.text = item.saldo >= 0 ? item.nama + " -> " + this.formatRupiah(item.saldo) : item.nama + "( " + this.formatRupiah(item.saldo * -1) + " )";
          // });
          this.data = response;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    // getAkun() {
    //   this.$store
    //     .dispatch("akun/getData", {})
    //     .then(() => {
    //       let akuns = JSON.parse(JSON.stringify(this.$store.state.akun.datas));
    //       akuns.map((item) => {
    //         item.value = item.id;
    //         item.text = item.saldo >= 0 ? item.nama + " -> " + this.formatRupiah(item.saldo) : item.nama + "( " + this.formatRupiah(item.saldo * -1) + " )";
    //       });

    //       this.debitAkun = akuns.filter((akun) => [this.JENIS_AKUN.debit, this.JENIS_AKUN.debit_kredit].includes(akun.jenis));
    //       this.kreditAkun = akuns.filter((akun) => [this.JENIS_AKUN.kredit, this.JENIS_AKUN.debit_kredit].includes(akun.jenis));
    //     })
    //     .catch((e) => {
    //       this.displayError(e);
    //       return false;
    //     });
    // },
  },
};
</script>
