var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.data ? _c('b-card', {
    staticClass: "earnings-card"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-card-title', {
    staticClass: "mb-1"
  }, [_vm._v(" Earnings ")]), _c('div', {
    staticClass: "font-small-2"
  }, [_vm._v(" This Month ")]), _c('h5', {
    staticClass: "mb-1"
  }, [_vm._v(" $4055.56 ")]), _c('b-card-text', {
    staticClass: "text-muted font-small-2"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v("68.2%")]), _c('span', [_vm._v(" more earnings than last month.")])])], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('vue-apex-charts', {
    attrs: {
      "height": "120",
      "options": _vm.earningsChart.chartOptions,
      "series": _vm.data.series
    }
  })], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }