var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.data ? _c('b-card', {
    staticClass: "card-congratulation-medal"
  }, [_c('h5', [_vm._v("Selamat Datang " + _vm._s(_vm.userData.karyawan.nama_lengkap) + "!")]), _c('b-card-text', {
    staticClass: "font-small-3"
  }), _c('h3', {
    staticClass: "mb-75 mt-2 pt-50"
  }, [_c('b-link', [_vm._v("$" + _vm._s(_vm.kFormatter(_vm.data.saleToday)))])], 1), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" View Sales ")]), _c('b-img', {
    staticClass: "congratulation-medal",
    attrs: {
      "src": require('@/assets/images/illustration/badge.svg'),
      "alt": "Medal Pic"
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }