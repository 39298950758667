var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_vm.isCEO ? [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_c('dashboard-ceo')], 1)], 1)] : _vm._e(), _vm.isHeadwh ? [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_c('dashboard-headwh')], 1)], 1)] : [[_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_c('dashboard-penerimaan')], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_c('dashboard-hr')], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_c('dashboard-procurement')], 1)], 1)]]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }