var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "xl": "12",
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-card', {
    staticClass: "card-statistics",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v(_vm._s(_vm.$t('Procurement')))]), _c('b-card-text', {
    staticClass: "font-small-2 mr-25 mb-0"
  })], 1), _c('b-card-body', {
    staticClass: "statistics-body"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-md-0",
    attrs: {
      "md": "4",
      "sm": "6"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-2"
  }, [_c('b-avatar', {
    attrs: {
      "size": "48",
      "variant": "secondary"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "24",
      "icon": "CircleIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(" " + _vm._s(_vm.data ? _vm.data.in_transit_stock : 0) + " ")]), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('In Transit Stock')) + " ")])], 1)], 1)], 1), _c('b-col', {
    staticClass: "mb-md-0",
    attrs: {
      "md": "4",
      "sm": "6"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-2"
  }, [_c('b-avatar', {
    attrs: {
      "size": "48",
      "variant": "warning"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "24",
      "icon": "LoaderIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(" " + _vm._s(_vm.data ? _vm.data.on_checking_stock : 0) + " ")]), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('On Checking Stock')) + " ")])], 1)], 1)], 1), _c('b-col', {
    staticClass: "mb-md-0",
    attrs: {
      "md": "4",
      "sm": "6"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-2"
  }, [_c('b-avatar', {
    attrs: {
      "size": "48",
      "variant": "success"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "24",
      "icon": "CheckCircleIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(" " + _vm._s(_vm.data ? _vm.data.received_stock : 0) + " ")]), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('Received Stock')) + " ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }